.draft-js-editor .public-DraftEditor-content {
  min-height: 12rem;
  /*line-height: 1.75rem*/
}
.draft-js-editor.read-only-editor .public-DraftEditor-content {
  min-height: auto;
}
.draft-js-editor .public-DraftEditor-content::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.draft-js-editor .public-DraftEditor-content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #888;
}
.draft-js-editor .public-DraftEditor-content::-webkit-scrollbar-thumb:hover {

}

.read-only-link-tooltip {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.draft-js-editor .text-center {
  text-align: center;
  text-align-last: center;
}
.draft-js-editor .text-left {
  text-align: left;
  text-align-last: left;
}
.draft-js-editor .text-right {
  text-align: right;
  text-align-last: right;
}
.draft-js-editor .text-justify {
  text-align: justify;
  text-align-last: justify;
}

.draft-js-editor .indent-1 {
  text-indent: 1rem;
}
.draft-js-editor .indent-2 {
  text-indent: 2rem;
}
.draft-js-editor .indent-3 {
  text-indent: 3rem;
}
.draft-js-editor .indent-4 {
  text-indent: 4rem;
}
