.hoverable .show-on-hover {
  display: none;
}
.hoverable:hover .show-on-hover {
  display: block;
}
.hoverable:hover .show-on-hover.show-on-bottom {
  top: 100%;
}
.hoverable:hover .show-on-hover.show-on-top {
  bottom: 100%;
}

.scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: #bbb;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #888;
}
.scrollbar::-webkit-scrollbar-thumb:hover {

}
.scrollbar-sm::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #bbb;
}
.scrollbar-sm::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #888;
}
.scrollbar-sm::-webkit-scrollbar-thumb:hover {

}




